// @flow
@import "./colors";

/*
Template Name: Osahan Eat - Online Food Ordering Website HTML Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/
/*
-- Body
-- Nav
-- login/Register
-- Homepage Search Block
-- Homepage
-- Customize Bootstrap
-- Listing
-- Detail
-- Checkout Page
-- My Account
-- Track Order Page
-- Footer
-- Mobile Media
*/
/* Body */
body {
  background  : $lightGreenBackground;
  font-size   : 13px;
  font-weight : 500;
  height      : auto;
  overflow    : auto;
}

button, a {
  outline         : none !important;
  color           : $primary;
  text-decoration : none !important;
}

p {
  font-size : 13px;
  color     : $textLight;
}

img {
  image-rendering : auto;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color : $text;
}

/* Nav */
.osahan-nav {
  background : #FFF none repeat scroll 0 0 !important;
  padding    : 0;
  z-index    : 9;
}

.osahan-nav .nav-link {
  font-weight : 600;
  padding     : 28px 0 !important;
  margin      : 0 0 0 31px;
}

.dropdown-cart .dropdown-menu {
  padding-top : 0 !important;
}

.nav-osahan-pic {
  width          : 32px;
  height         : 32px;
  border         : 3px solid $white;
  box-shadow     : 0px 0px 3px #CCC;
  position       : relative;
  margin         : -8px 5px -6px 0;
  vertical-align : text-top;
}

.dropdown-cart-top {
  border-top : 2px solid $primary;
  min-width  : 420px;
}

.dropdown-cart-top-header {
  background : $primaryLight2;
}

.dropdown-cart-top-header .img-fluid {
  border           : 1px solid #DCDCDC;
  border-radius    : 3px;
  float            : left;
  height           : 60px;
  padding          : 3px;
  width            : 80px;
  object-fit       : contain;
  background-color : #FFF;
}

.dropdown-cart-top-header p.text-secondary {
  font-size   : 11px;
  line-height : 24px;
  font-weight : 600;
}

.dropdown-cart-top-header h6 {
  font-size : 14px;
}

.food-item {
  border        : 1px solid;
  border-radius : 2px;
  display       : inline-block;
  font-size     : 6px;
  height        : 13px;
  line-height   : 12px;
  text-align    : center;
  width         : 13px;
}

.dropdown-cart-top-body .food-item {
  float        : left;
  margin       : 4px 10px 0 0;
  padding-left : 1px;
}

/* login/Register */
:root {
  --input-padding-x : 1.5rem;
  --input-padding-y : 0.75rem;
}

.login,
.image {
  min-height : 100vh;
}

.bg-image {
  background-size     : cover;
  background-position : right;
}

.login-heading {
  font-weight : 300;
}

.btn-login {
  font-size      : 0.9rem;
  letter-spacing : 0.05rem;
  padding        : 0.75rem 1rem;
  border-radius  : 2rem;
}

.form-label-group {
  position      : relative;
  margin-bottom : 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding       : 15px 0;
  border-radius : 0;
  height        : 51px;
  background    : transparent !important;
  border        : none;
  border-bottom : 1px solid #CED4DA;
  box-shadow    : none !important;
}

.form-label-group > label {
  position      : absolute;
  top           : 0;
  left          : 0;
  display       : block;
  width         : 100%;
  margin-bottom : 0;
  /* Override default `<label>` margin */
  line-height   : 1.5;
  color         : #495057;
  cursor        : text;
  /* Match the input under the label */
  border        : 1px solid transparent;
  border-radius : .25rem;
  transition    : all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color : transparent;
}

.form-label-group input:-ms-input-placeholder {
  color : transparent;
}

.form-label-group input::-ms-input-placeholder {
  color : transparent;
}

.form-label-group input::-moz-placeholder {
  color : transparent;
}

.form-label-group input::placeholder {
  color : transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top    : calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom : calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top    : calc(var(--input-padding-y) / 3);
  padding-bottom : calc(var(--input-padding-y) / 3);
  font-size      : 12px;
  color          : #777;
}

.form-control:focus {
  border-color : $primaryNeon;
}

.btn-google {
  color            : white;
  background-color : #EA4335;
}

.btn-facebook {
  color            : white;
  background-color : #3B5998;
}

/* Homepage Search Block */
.homepage-search-block {
  padding-top      : 3.25rem;
  padding-bottom   : 3.25rem;
  background-color : $lightGreenBackground;
  background-size  : cover;


  h1, h5 {
	text-shadow : transparentize($text, 0.8) 0 2px 2px;
  }
}

.homepage-search-form .form-control {
  font-size     : 15px;
  height        : 50px;
  padding-right : 106px;
}

.homepage-search-form .form-group {
  position : relative;
}

.homepage-search-form .btn {
  font-size   : 15px;
  font-weight : 600;
  height      : 50px;
  padding     : 13px 5px;
}

.homepage-search-form .location-dropdown i {
  left     : 18px;
  position : absolute;
  top      : 20px;
  z-index  : 9;
}

.homepage-search-form .select2-selection {
  border-color  : #CED4DA !important;
  border-radius : 0.25rem !important;
  height        : 50px !important;
  outline       : none !important;
}

.homepage-search-form .select2-selection__rendered {
  font-size   : 15px;
  line-height : 47px !important;
  padding     : 0 28px 0 31px !important;
}

.homepage-search-form .select2-selection__arrow {
  right : 9px !important;
  top   : 13px !important;
}

.select2-dropdown {
  margin        : -2px 0 0 0;
  border        : 1px solid #CED4DA;
  border-radius : 3px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border : 1px solid #CED4DA;
}

.select2-search__field {
  outline : none !important;
  border  : 1px solid #CED4DA;
}

.owl-carousel {
  position : relative;
}

.owl-theme .owl-nav [class*="owl-"] {
  position        : absolute;
  bottom          : calc(50% - 20px);
  background      : $white none repeat scroll 0 0 !important;
  border-radius   : 30px !important;
  box-shadow      : 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  color           : #000 !important;
  height          : 40px;
  line-height     : 40px;
  margin          : 0 !important;
  text-align      : center;
  text-transform  : uppercase;
  width           : 40px;
  display         : flex !important;
  align-items     : center;
  justify-content : center;
}

.owl-theme .owl-nav [class*="owl-"] i {
  font-size   : 15px !important;
  font-weight : normal !important;
}

.owl-theme .owl-nav .owl-prev {
  margin-left : -16px !important;

  i {
	margin-right : 2px;
  }
}

.owl-theme .owl-nav .owl-next {
  right        : 0;
  margin-right : -16px !important;

  i {
	margin-left : 2px;
  }
}

/* Homepage */
.owl-carousel-four .item {
  padding : 6px 7px;
}

.category-item {
  background    : $white;
  margin        : 0 2px 5px 2px;
  border-radius : 3px;
  overflow      : hidden;
  text-align    : center;
  padding       : 7px;
  box-shadow    : 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  border        : 1px solid $white;

  &:hover {
	border : 1px solid $primary;
  }

  a:hover, a:hover h6 {
	color : $primaryLight !important;
  }

  h6 {
	margin    : 0;
	font-size : 13px;
	color     : $primary;
  }

  p {
	margin    : 0;
	font-size : 12px;
  }

  img, i {
	width         : 40px !important;
	height        : 40px;
	object-fit    : cover;
	display       : inline-block !important;
	border-radius : 50px;
	box-shadow    : 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
	margin        : 2px 0 8px 0;
  }

  i {
	vertical-align : center;
	text-align     : center;
	font-size      : 24px;
	padding        : 8px;
  }
}

.line {
  background-color : #5E5E5E;
  border-radius    : 12px;
  display          : inline-table;
  height           : 4px;
  margin           : 10px 0 51px;
  width            : 50px;
}

/* Customize Bootstrap */
.small, small {
  font-size   : 85%;
  font-weight : 600;
}

.select2, .select2 span {
  width : 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  left : 96%;
}

.select2-container--default .select2-selection--single {
  background-color : $white;
  border           : 1px solid #CED4DA;
  border-radius    : 3px;
}

.modal-footer .btn {
  font-size : 14px;
  padding   : 12px 16px;
}

.btn-success,
.badge-success {
  background-color : $primaryLight !important;
  border-color     : $primaryLight !important;
}

.btn-outline-success {
  color        : $primaryLight !important;
  border-color : $primaryLight !important;
}

.btn-outline-success:hover {
  color            : $white !important;
  border-color     : $primaryLight !important;
  background-color : $primaryLight !important;
}

.text-success {
  color : $primaryLight !important;
}

.border-success {
  border-color : $primaryLight !important;
}

.btn-danger,
.badge-danger {
  background-color : $danger !important;
  border-color     : $danger !important;
}

.btn-outline-danger {
  color        : $danger !important;
  border-color : $danger !important;
}

.btn-outline-danger:hover {
  color            : $white !important;
  border-color     : $danger !important;
  background-color : $danger !important;
}

.text-danger {
  color : $danger !important;
}

.border-danger {
  border-color : $danger !important;
}

.badge-danger {
  background-color : $danger !important;
  border-color     : $danger !important;
}

.text-primary {
  color : $primary !important;
}

.text-primaryNew {
  color : $primaryNew !important;
}

.border-primary {
  border-color : $primary !important;
}

.badge-primary {
  background-color : $primary !important;
  border-color     : $primary !important;
}

.btn-primary {
  background-color : $primary !important;
  border-color     : $primary !important;
}

.btn-outline-primary {
  color        : $primary !important;
  border-color : $primary !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color            : $white !important;
  border-color     : $primary !important;
  background-color : $primary !important;
}

.text-primary {
  color : $primary !important;
}

.border-primary {
  border-color : $primary !important;
}

.btn-lg {
  font-size      : 15px;
  font-weight    : bold;
  padding        : 14px 35px;
  letter-spacing : 1px;
}

.modal-content {
  border        : none;
  border-radius : 2px;
}

.btn {
  border-radius : 2px;
}

.btn-link {
  color : $primary;
}

.form-control {
  font-size     : 13px;
  border-radius : 2px;
  min-height    : 38px;
}

.card {
  border-radius : 2px;
}

.rounded {
  border-radius : 2px !important;
}

.input-group-text {
  border-radius : 2px;
}

.custom-checkbox .custom-control-label::before {
  border-radius : 2px;
}

.nav-pills .nav-link {
  border-radius : 2px;
}

.alert,
.badge,
.dropdown-menu {
  border-radius : 2px;
}

.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  border-radius : 2px;
}

.bg-dark {
  background-color : #000 !important;
}

.pb-5,
.py-5 {
  padding-bottom : 4rem !important;
}

.pt-5,
.py-5 {
  padding-top : 4rem !important;
}

.dropdown-menu {
  font-size : 13px;
}

.dropdown-item {
  border-radius : 0 !important;
  padding       : 7px 18px;
  color         : rgba(0, 0, 0, .5);
  font-weight   : 500;
}

.dropdown-item:focus {
  background-color : $primary;
  color            : $white;
}

/* Listing */
.list-cate-page {
  margin : -5px 0px 0px 0px;
}

.filters-card-body {
  padding : 18px 0 0 0;
}

.filters-search {
  position : relative;
}

.filters-search i {
  position : absolute;
  left     : 12px;
  top      : 13px;
}

.custom-checkbox label.custom-control-label,
.custom-radio label.custom-control-label {
  padding-top : 2px;
  cursor      : pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color     : $primaryNeon;
  background-color : $primaryNeon;
}

.filters-card-header a {
  font-weight : 500;
  width       : 100%;
  display     : block;
}

.filters-card-header a i {
  margin : 2px -2px 0 0;
}

.position-absolute {
  z-index : 1;
}

iframe.position-absolute {
  z-index : 0;
}

.list-card .count-number {
  margin  : 4px 0 0 0;
  display : inline-block;
}

.list-card a {
  color      : inherit;
  word-break : break-word;
}

.list-card a:hover {
  color : $primaryLight;
}

.list-card .star {
  right  : 8px;
  bottom : 8px;
}

.list-card .star .badge {
  font-size  : 11px;
  padding    : 5px 5px;
  box-shadow : 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.list-card .member-plan {
  left : 8px;
  top  : 8px;
}

.list-card .member-plan .badge {
  font-size  : 11px;
  padding    : 5px 5px;
  box-shadow : 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.list-card .list-card-image {
  position : relative;
}

.list-card .favourite-heart {
  right      : 8px;
  top        : 8px;
  box-shadow : 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.list-card .favourite-heart i {
  background    : $white;
  width         : 23px;
  height        : 23px;
  display       : inline-block;
  text-align    : center;
  font-size     : 15px;
  line-height   : 24px;
  border-radius : 50px;
}

/* detail */
.count-number .btn {
  padding       : 2px 5px;
  font-size     : 12px;
  border-radius : 0;
}

.restaurant-detailed-header-left {
  text-shadow : 0 0 10px #000;
}

.count-number-input {
  width          : 24px;
  text-align     : center;
  margin         : 0 -4px;
  background     : #6C757D;
  border         : none;
  color          : $white;
  height         : 24px;
  border-radius  : 0px;
  vertical-align : bottom;
}

.generator-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff3008+1,fe792b+100 */
  background : $primary; /* Old browsers */
  background : -moz-linear-gradient(-45deg, $primary 1%, $primaryLight 100%); /* FF3.6-15 */
  background : -webkit-linear-gradient(-45deg, $primary 1%, $primaryLight 100%); /* Chrome10-25,Safari5.1-6 */
  background : linear-gradient(135deg, $primary 1%, $primaryLight 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr=$primary, endColorstr=$primaryLight, GradientType=1); /* IE6-9 fallback on horizontal gradient */
}

.restaurant-detailed-banner {
  position : relative;
}

.restaurant-detailed-banner .img-fluid.cover {
  height : 424px;
  //object-fit: contain;
  //width: 100%;
}

.restaurant-detailed-header {
  bottom     : 0;
  left       : 0;
  padding    : 65px 0 17px;
  position   : absolute;
  right      : 0;
  background : -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
  background : -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
  background : linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
  filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#d4000000', GradientType=0);
}

.homepage-great-deals-carousel .item img {
  border-radius : 2px;
}

.restaurant-detailed-earn-pts .img-fluid.float-left {
  height : 61px;
}

.card-icon-overlap {
  overflow : hidden;
  position : relative;
}

.red-generator-bg {
  background : #FF3371;
  background : -moz-linear-gradient(-45deg, #FF3371 1%, #FE6753 100%);
  background : -webkit-linear-gradient(-45deg, #FF3371 1%, #FE6753 100%);
  background : linear-gradient(135deg, #FF3371 1%, #FE6753 100%);
  filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3371', endColorstr='#fe6753', GradientType=1);
}

.border-btn {
  border        : 1px solid #DEE0E6;
  border-radius : 2px;
  display       : inline-block;
  font-size     : 14px;
  font-weight   : 500;
  padding       : 8px 15px;
  margin        : 0 0 10px 0;
}

.restaurant-detailed-ratings .generator-bg {
  border-radius : 4px;
  display       : inline-block;
  font-size     : 12px;
  height        : 20px;
  margin        : 0 4px 0 0;
  padding       : 3px 6px;
}

.explore-outlets-search .form-control {
  border-radius : 2px !important;
  font-size     : 15px;
  height        : 50px;
  padding       : 5px 20px;
}

.explore-outlets-search .btn {
  color           : #777;
  font-size       : 15px;
  padding         : 13px 17px;
  position        : absolute;
  z-index         : 9;
  right           : 0;
  text-decoration : none;
}

.mall-category-item {
  border        : 1px solid rgba(0, 0, 0, 0.1);
  border-radius : 3px;
  margin        : 0 6px;
  overflow      : hidden;
  text-align    : center;
  padding       : 0 0 7px 0;
}

.mall-category-item img {
  height     : 89px;
  object-fit : cover;
  width      : 100%;
}

.mall-category-item h6 {
  font-size   : 14px;
  font-weight : 500;
  margin      : 0;
  padding     : 10px 10px 3px 10px;
}

.mall-category-item .btn {
  padding   : 2px 8px;
  font-size : 12px;
  right     : 7px;
  top       : 7px;
}

.mall-category-item small {
  background    : #ECECEC;
  color         : #F32129;
  padding       : 1px 6px;
  border-radius : 2px;
  font-size     : 10px;
}

.mall-category-item:hover small {
  background : #F32129;
  color      : $white;
}

.address-map {
  border-radius : 2px;
  overflow      : hidden;
}

.new-arrivals-card .card-img .new-arrivals {
  bottom   : 11px;
  left     : 12px;
  position : absolute;
}

.new-arrivals {
  background     : #000 none repeat scroll 0 0;
  border-radius  : 2px;
  color          : $white;
  padding        : 1px 13px;
  text-transform : uppercase;
}

.new-arrivals-card .card-img {
  position : relative;
}

.total-like-user {
  border     : 2px solid $white;
  height     : 34px;
  box-shadow : 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  width      : 34px;
}

.total-like-user-main a {
  display : inline-block;
  margin  : 0 -17px 0 0;
}

.total-like {
  border         : 1px solid;
  border-radius  : 50px;
  display        : inline-block;
  font-weight    : 500;
  height         : 34px;
  line-height    : 33px;
  padding        : 0 13px;
  vertical-align : top;
}

.restaurant-detailed-ratings-and-reviews hr {
  margin : 0 -24px;
}

.star-rating {
  cursor : pointer;
}

.graph-star-rating-header .star-rating {
  font-size : 17px;
}

.progress {
  background    : #F2F4F8 none repeat scroll 0 0;
  border-radius : 0;
  height        : 30px;
}

.rating-list {
  display       : inline-flex;
  margin-bottom : 15px;
  width         : 100%;
}

.rating-list-left {
  height      : 16px;
  line-height : 29px;
  width       : 10%;
}

.rating-list-center {
  width : 80%;
}

.rating-list-right {
  line-height : 29px;
  text-align  : right;
  width       : 10%;
}

.restaurant-slider-pics {
  bottom    : 0;
  font-size : 12px;
  left      : 0;
  z-index   : 999;
  padding   : 0 10px;
}

.restaurant-slider-view-all {
  bottom  : 15px;
  right   : 15px;
  z-index : 999;
}

.offer-dedicated-nav .nav-link.active,
.offer-dedicated-nav .nav-link:hover,
.offer-dedicated-nav .nav-link:focus {
  border-color : #3868FB;
  color        : #3868FB;
}

.offer-dedicated-nav .nav-link {
  border-bottom : 2px solid $white;
  color         : #000;
  padding       : 16px 0;
  font-weight   : 600;
}

.offer-dedicated-nav .nav-item {
  margin : 0 37px 0 0;
}

.restaurant-detailed-action-btn {
  margin-top : 12px;
}

.restaurant-detailed-header-right .btn-primary {
  border-radius : 3px;
  height        : 45px;
  margin        : -18px 0 18px;
  min-width     : 130px;
  padding       : 7px;
}

.text-black {
  color : #000;
}

.icon-overlap {
  bottom    : -23px;
  font-size : 74px;
  opacity   : 0.23;
  position  : absolute;
  right     : -32px;
}

.menu-list img {
  width      : 41px;
  height     : 41px;
  object-fit : cover;
}

.restaurant-detailed-header-left img {
  height        : 100%;
  max-height    : 100px;
  max-width     : 200px;
  border-radius : 3px;
  object-fit    : contain;
  box-shadow    : 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

/* Checkout Page */
.addresses-item a.btn.btn-sm {
  min-width : 157px;
}

.osahan-cart-item-profile img {
  width  : 40px;
  height : 40px;
  border : 3px solid $white;
}

.osahan-cart-item-profile p {
  font-size : 10px;
}

.osahan-payment .nav-link {
  padding     : 18px 20px;
  border      : none;
  font-weight : 600;
  color       : #535665;
}

.osahan-payment .nav-link i {
  width         : 28px;
  height        : 28px;
  background    : #535665;
  display       : inline-block;
  text-align    : center;
  line-height   : 29px;
  font-size     : 15px;
  border-radius : 50px;
  margin        : 0 7px 0 0px;
  color         : $white;
}

.osahan-payment .nav-link.active {
  background : $lightGreenBackground;
  color      : #282C3F !important;
}

.osahan-payment .nav-link.active i {
  background : #282C3F !important;
}

.osahan-payment .tab-content {
  background : $lightGreenBackground;
  padding    : 20px;
}

.osahan-card i {
  font-size      : 35px;
  vertical-align : middle;
  color          : #6C757D;
}

.osahan-card {
  margin : 0 0 0 7px;
}

.osahan-payment label {
  font-size   : 12px;
  margin      : 0 0 3px 0;
  font-weight : 600;
}

/* My Account */
.payments-item img.mr-3 {
  width : 47px;
}

.order-list .btn {
  border-radius : 2px;
  min-width     : 121px;
  font-size     : 13px;
  padding       : 7px 0 7px 0;
}

.osahan-account-page-left {
  .nav-link {
	padding     : 18px 20px;
	border      : none;
	font-weight : 600;
	color       : #535665;

	i {
	  width         : 32px;
	  height        : 32px;
	  background    : #535665;
	  display       : inline-block;
	  text-align    : center;
	  line-height   : 16px;
	  padding       : 8px;
	  font-size     : 16px;
	  border-radius : 50px;
	  margin        : 0 7px 0 0;
	  color         : #F2F2F2;

	  &:before {
		text-align : center;
	  }
	}


	&.active {
	  color : $primary !important;

	  i {
		background : $primary !important;
	  }
	}
  }
}

.osahan-user-media img {
  width : 90px;
}

.card.offer-card h5.card-title {
  border : 2px dotted #000;
}

.card.offer-card h5 {
  border        : 1px dotted #DACEB7;
  display       : inline-table;
  color         : #17A2B8;
  margin        : 0 0 19px 0;
  font-size     : 15px;
  padding       : 6px 10px 6px 6px;
  border-radius : 2px;
  background    : #FFFAE6;
  position      : relative;
}

.card.offer-card h5 img {
  height        : 22px;
  object-fit    : cover;
  width         : 22px;
  margin        : 0 8px 0 0;
  border-radius : 2px;
}

.card.offer-card h5:after {
  border-left   : 4px solid transparent;
  border-right  : 4px solid transparent;
  border-bottom : 4px solid #DACEB7;
  content       : "";
  left          : 30px;
  position      : absolute;
  bottom        : 0;
}

.card.offer-card h5:before {
  border-left  : 4px solid transparent;
  border-right : 4px solid transparent;
  border-top   : 4px solid #DACEB7;
  content      : "";
  left         : 30px;
  position     : absolute;
  top          : 0;
}

.card.offer-card .card-btn {
  font-size      : 13px;
  color          : $primary;
  vertical-align : top !important;
  border         : 0 !important;
}

.card.offer-card .card-btn:hover {
  color : #0056B3;
}

.payments-item .media {
  align-items : center;
}

.payments-item .media img {
  margin : 0 40px 0 11px !important;
}

.reviews-members .media .mr-3 {
  width      : 56px;
  height     : 56px;
  object-fit : cover;
}

.order-list img.mr-4 {
  width         : 70px;
  height        : 70px;
  object-fit    : contain;
  box-shadow    : 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  border-radius : 2px;
}

.osahan-cart-item p.text-gray.float-right {
  margin    : 3px 0 0 0;
  font-size : 12px;
}

.osahan-cart-item .food-item {
  vertical-align : bottom;
}

/* Track Order Page */

.osahan-track-order-page {
  min-height : 676px;
}

.osahan-point {
  z-index          : 1;
  width            : 50%;
  border-radius    : 50%;
  padding-bottom   : 50%;
  pointer-events   : none;
  background-color : transparentize($primary, 0.8);
  border           : 1px solid $primary;
}

/* Footer */
.footer {
  background : $lightGreenBackground;
}

.footer h6 {
  font-size      : 14px;
  text-transform : uppercase;
}

.footer ul {
  margin  : 0;
  padding : 0;
}

.footer ul li {
  line-height : 26px;
  list-style  : outside none none;
}

.footer ul li a {
  color : #6C8293;
}

.search-links a {
  color       : #666;
  font-size   : 12px;
  line-height : 23px;
  margin      : 0 2px;
}

.search-links {
  font-size : 11px;
}

footer {
  background-color : #F0F4F7;
}

/* Mobile Media */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width : 575.98px) {
  .m-none {
	display : none;
  }

  .homepage-search-title {
	text-align : center;
  }

  .list-cate-page {
	margin : 22px 0 0 0;
  }

  .d-none-m {
	display : none;
  }

  .row.pt-2.pb-2 {
	padding-bottom : 0 !important;
	padding-top    : 0 !important;
  }

  .row.pt-2 {
	padding-top : 0 !important;
  }

  .offer-card-horizontal .col-md-4.col-4.p-4 {
	padding : 0 0 0 15px !important;
  }

  .row.mt-4.pt-2 {
	margin-top  : 0 !important;
	padding-top : 0 !important;
  }

  .homepage-add-section {
	padding : 0 13px;
  }


  .breadcrumb-osahan h1 {
	font-size : 34px;

  }


  .breadcrumb-osahan h6 {
	font-size : 15px;
  }

  .breadcrumb-osahan {
	padding-left  : 15px;
	padding-right : 15px;
  }

  .reviews-members .media .mr-3 {
	width  : 36px;
	height : 36px;
  }

  .total-like {
	font-size : 12px;
  }

  .restaurant-detailed-action-btn.float-right .btn {
	margin    : 3px 0;
	font-size : 13px;
  }

  .login .col-md-9.col-lg-8.mx-auto.pl-5.pr-5 {
	padding : 0 11px !important;
  }

  .login.d-flex.align-items-center.py-5 {
	padding : 0 !important;
  }

  .h5, h5 {
	font-size : 16px;
  }

  .homepage-add-section .col-md-3.col-6 {
	padding : 2px 2px;
  }

  .homepage-ad .item {
	padding : 0 2px;
  }

  .osahan-nav {
	padding : 15px 15px;
  }

  .navbar-toggler {
	padding       : 5px 8px;
	border-radius : 2px;
  }

  .osahan-nav .nav-link {
	margin        : 0;
	text-align    : center;
	padding       : 14px 0 !important;
	border-bottom : 1px solid $lightGreenBackground;
  }

  .osahan-nav .dropdown-item {
	text-align : center;
  }

  .osahan-slider {
	padding : 18px 0 0 0 !important;
  }

  .pt-5, .py-5 {
	padding-top : 3rem !important;
  }

  .pb-5, .py-5 {
	padding-bottom : 3rem !important;
  }

  .mobile-none {
	display : none;
  }

  .card {
	margin-bottom : 15px !important;
  }

  p {
	font-size : 12px;
  }

  .restaurant-detailed-header-left img {
	float  : none !important;
	margin : 0 0 14px 0 !important;
  }

  .restaurant-detailed-header-left {
	text-align : center;
  }

  .restaurant-detailed-header-right .btn-primary {
	margin-top : 18px;
  }

  .restaurant-detailed-header-right.text-right {
	text-align : center !important;
  }

  .restaurant-detailed-action-btn.float-right {
	float         : none !important;
	margin        : auto;
	text-align    : center;
	width         : 100% !important;
	display       : block;
	padding       : 12px 0;
	border-bottom : 1px solid #DEDEDE;
  }

  .offer-dedicated-nav {
	text-align : center;
  }

  .offer-dedicated-nav .nav-item {
	flex          : auto;
	border-bottom : 1px solid #CCC;
	padding       : 0;
	margin        : 0 11px !important;
  }

  .offer-dedicated-nav {
	text-align : center;
  }

  .address-map.float-right.ml-5 {
	float  : none !important;
	margin : 0 0 20px 0 !important;
  }

  .address-map.float-right.ml-5 iframe {
	width : 100%;
  }

  .osahan-track-order-detail p.text-gray.mb-5 {
	margin : 1px 0 11px 0 !important;
  }

  .osahan-account-page-left .nav-tabs {
	padding-left : 0 !important;
  }

  .osahan-account-page-left {
	height : auto !important;
	margin : 0 0 15px 0;
  }

  .order-list .float-right {
	float : none !important;
  }

  .row.mb-4 {
	margin-bottom : 0 !important;
  }

  .app {
	margin-bottom : 26px;
  }

  .footer ul li a {
	font-size : 12px;
  }

  .footer h6 {
	font-size      : 13px;
	text-transform : capitalize;
  }

  .osahan-payment .col-sm-4.pr-0 {
	padding-right : 15px !important;
  }

  .osahan-payment .col-sm-8.pl-0 {
	padding-left : 15px !important;
	margin       : 14px 0 0 0;
  }

  .p-5.osahan-invoice.bg-white.shadow-sm {
	padding : 15px !important;
  }

  .h3, h3 {
	font-size : 22px;
  }

  .p-5 {
	padding : 20px !important;
  }

  .osahan-account-page-right {
	padding    : 0 !important;
	border     : none;
	background : transparent !important;
	box-shadow : none !important;
	margin-top : 15px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width : 576px) and (max-width : 767.98px) {
  .homepage-add-section .col-md-3.col-6 {
	padding : 2px 2px;
  }

  .homepage-search-title {
	text-align : center;
  }

  .row.pt-2.pb-2 {
	padding-bottom : 0 !important;
	padding-top    : 0 !important;
  }

  .row.pt-2 {
	padding-top : 0 !important;
  }

  .d-none-m {
	display : none;
  }

  .list-cate-page {
	margin : 22px 0 0 0;
  }

  .row.mt-4.pt-2 {
	margin-top  : 0 !important;
	padding-top : 0 !important;
  }

  .offer-card-horizontal .col-md-4.col-4.p-4 {
	padding : 0 0 0 15px !important;
  }

  .homepage-add-section {
	padding : 0 13px;
  }

  .homepage-ad .item {
	padding : 0 2px;
  }

  .container {
	max-width : 100%;
  }

  .osahan-nav {
	padding : 15px 15px;
  }

  .navbar-toggler {
	padding       : 5px 8px;
	border-radius : 2px;
  }

  .osahan-nav .nav-link {
	margin        : 0;
	text-align    : center;
	padding       : 14px 0 !important;
	border-bottom : 1px solid $lightGreenBackground;
  }

  .osahan-nav .dropdown-item {
	text-align : center;
  }

  .osahan-slider {
	padding : 18px 0 0 0 !important;
  }

  .pt-5, .py-5 {
	padding-top : 3rem !important;
  }

  .pb-5, .py-5 {
	padding-bottom : 3rem !important;
  }

  .homepage-search-block {
	padding : 3rem 0 !important;
  }

  .mobile-none {
	display : none;
  }

  .card {
	margin-bottom : 15px !important;
  }

  p {
	font-size : 12px;
  }

  .restaurant-detailed-header-left img {
	float  : none !important;
	margin : 0 0 14px 0 !important;
  }

  .restaurant-detailed-header-left {
	text-align : center;
  }

  .restaurant-detailed-header-right .btn-primary {
	margin-top : 18px;
  }

  .restaurant-detailed-header-right.text-right {
	text-align : center !important;
  }

  .restaurant-detailed-action-btn.float-right {
	float         : none !important;
	margin        : auto;
	text-align    : center;
	width         : 100% !important;
	display       : block;
	padding       : 12px 0;
	border-bottom : 1px solid #DEDEDE;
  }

  .offer-dedicated-nav {
	text-align : center;
  }

  .offer-dedicated-nav .nav-item {
	flex          : auto;
	border-bottom : 1px solid #CCC;
	padding       : 0;
	margin        : 0 11px !important;
  }

  .offer-dedicated-nav {
	text-align : center;
  }

  .address-map.float-right.ml-5 {
	float  : none !important;
	margin : 0 0 20px 0 !important;
  }

  .address-map.float-right.ml-5 iframe {
	width : 100%;
  }

  .osahan-payment .nav-link i {
	display : block;
	margin  : 0 auto 10px auto;
  }

  .osahan-payment .nav-link {
	text-align : center;
  }

  .osahan-track-order-detail p.text-gray.mb-5 {
	margin : 1px 0 11px 0 !important;
  }

  .osahan-account-page-left .nav-tabs {
	padding-left : 0 !important;
  }

  .osahan-account-page-left {
	height : auto !important;
	margin : 0 0 15px 0;
  }

  .order-list .float-right {
	float : none !important;
  }

  .row.mb-4 {
	margin-bottom : 0 !important;
  }

  .app {
	margin-bottom : 26px;
  }

  .footer ul li a {
	font-size : 12px;
  }

  .footer h6 {
	font-size      : 13px;
	text-transform : capitalize;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width : 768px) and (max-width : 991.98px) {
  .container {
	max-width : 100%;
  }

  .osahan-nav {
	padding : 15px 15px;
  }

  .navbar-toggler {
	padding       : 5px 8px;
	border-radius : 2px;
  }

  .osahan-nav .nav-link {
	margin        : 0;
	text-align    : center;
	padding       : 14px 0 !important;
	border-bottom : 1px solid $lightGreenBackground;
  }

  .osahan-nav .dropdown-item {
	text-align : center;
  }

  .osahan-slider {
	padding : 18px 0 0 0 !important;
  }

  .pt-5, .py-5 {
	padding-top : 3rem !important;
  }

  .pb-5, .py-5 {
	padding-bottom : 3rem !important;
  }

  .homepage-search-block {
	padding : 3rem 0 !important;
  }

  .card {
	margin-bottom : 15px !important;
  }

  p {
	font-size : 12px;
  }

  .restaurant-detailed-header-left img {
	float  : none !important;
	margin : 0 0 14px 0 !important;
  }

  .restaurant-detailed-header-left {
	text-align : center;
  }

  .restaurant-detailed-header-right .btn-primary {
	margin-top : 18px;
  }

  .restaurant-detailed-header-right.text-right {
	text-align : center !important;
  }

  .restaurant-detailed-action-btn.float-right {
	float         : none !important;
	margin        : auto;
	text-align    : center;
	width         : 100% !important;
	display       : block;
	padding       : 12px 0;
	border-bottom : 1px solid #DEDEDE;
  }

  .offer-dedicated-nav {
	text-align : center;
  }

  .offer-dedicated-nav .nav-item {
	margin : 0 8px !important;
  }

  .offer-dedicated-nav {
	text-align : center;
  }

  .address-map.float-right.ml-5 {
	float  : none !important;
	margin : 0 0 20px 0 !important;
  }

  .address-map.float-right.ml-5 iframe {
	width : 100%;
  }

  .osahan-payment .nav-link i {
	display : block;
	margin  : 0 auto 10px auto;
  }

  .osahan-payment .nav-link {
	text-align : center;
  }

  .osahan-track-order-detail p.text-gray.mb-5 {
	margin : 1px 0 11px 0 !important;
  }

  .osahan-account-page-left .nav-tabs {
	padding-left : 0 !important;
  }

  .osahan-account-page-left {
	height : auto !important;
	margin : 0 0 15px 0;
  }

  .order-list .float-right {
	float : none !important;
  }

  .row.mb-4 {
	margin-bottom : 0 !important;
  }

  .app {
	margin-bottom : 26px;
  }

  .footer ul li a {
	font-size : 12px;
  }

  .footer h6 {
	font-size      : 13px;
	text-transform : capitalize;
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background : $primaryLight;
}

/* Large devices (desktops, 992px and up) */
@media (min-width : 992px) and (max-width : 1199.98px) {
  .container {
	max-width : 100%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width : 1200px) {
}

.input-foreground {
  position : relative;
  z-index  : 2;
}

.input-foreground:-webkit-autofill,
.input-foreground:-webkit-autofill:hover,
.input-foreground:-webkit-autofill:focus {
  border-bottom           : 1px solid #CED4DA;
  -webkit-text-fill-color : $text;
  -webkit-box-shadow      : transparent;
  background-color        : transparent !important;
  transition              : background-color 50000000s ease-in-out 0s;
}

.input-foreground:-webkit-autofill:focus {
  border-bottom : 1px solid #97BF04;
}

.plan-card {
  cursor : pointer;

  .card {
	height : 100%;
  }
}

.plan-card.active .card, .plan-card:hover .card {
  border : 1px solid $primary;
}

.plan-card:hover .card {
  box-shadow : 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
}


.my-uploadfield {
  .dzu-dropzone {
	border     : 2px dashed $primaryLight;
	min-height : 100px;

	.dzu-inputLabel {
	  margin      : 0;
	  font-weight : lighter;
	  font-size   : 1rem;
	}

	.dzu-previewContainer {
	  border     : none;
	  min-height : 100px;
	}
  }

  .dzu-currentFiles {
	margin-top : 10px;
  }

  .dzu-previewContainer {
	border  : 1px solid $primaryLight;
	padding : 10px;

	.dzu-previewStatusContainer {
	  margin-right : 1rem;
	  font-size    : 1rem;
	  color        : $textLight;

	  .dzu-previewButton {
		margin-top  : -5px;
		margin-left : 2rem;
		min-height  : 1rem;
		min-width   : 1rem;
		color       : $primary;
		padding     : 0;

		&:hover {
		  color : $danger;
		}
	  }
	}

	.dzu-previewImage {
	  max-height    : 80px;
	  max-width     : 200px;
	  border-radius : 0;
	}

	&:not(:last-child) {
	  border-bottom : none;
	}
  }
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
  color : $primary;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color : $primary;
  color        : #FFF;
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color : $primary;
  background   : $primary;
  color        : #FFF;
}

.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color : $primary;
}

.ck-editor__editable {
  min-height : 250px;
}

.cursor-pointer {
  cursor : pointer;
}

.show.fade {
  opacity : 1;
}

.vendor-card-header {
  min-height : 100px;
}

.vendor-card-logo {
  img {
	border-radius : 3px;
	object-fit    : cover;
	box-shadow    : 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
}

.customMarker {
  z-index       : 10;
  background    : #FFF;
  width         : 250px;
  border-radius : 2px;
  box-shadow    : 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

  position      : absolute;
  left          : -135px;

  padding-top   : 40px !important;

  img {
	max-width : 100%;
	//height: 100%;
	//max-height: 60px;
	//max-width: 100px;
	//border-radius: 4px;
	//object-fit: contain;
	//box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  &:after {
	content      : '';
	position     : absolute;
	left         : calc(50% - 10px);
	bottom       : -20px;
	width        : 0;
	height       : 0;
	border-left  : 20px solid transparent;
	border-right : 20px solid transparent;
	border-top   : 20px solid #FFF;
	clear        : both;

  }

  &:before {
	content      : '';
	position     : absolute;
	left         : calc(50% - 11px);
	bottom       : -22px;
	width        : 0;
	height       : 0;
	border-left  : 22px solid transparent;
	border-right : 22px solid transparent;
	border-top   : 22px solid rgba(0, 0, 0, 0.075);
	clear        : both;
  }
}

.product-marker {
  img {
	object-fit      : cover;
	max-height      : 100%;
	max-width       : 100%;
	height          : 250px;
	border-radius   : 0;
	object-position : center;
  }
}

.clusterMarker {
  width : 350px;
  left  : -185px;

  .img-container {
	height   : 80px;
	overflow : hidden;
  }

  img {
	height     : 80px;
	object-fit : contain;
  }

  .inner {
	max-height : 460px;
	overflow-y : auto;
	overflow-x : hidden;
  }

}

.clusterNumber {
  position      : absolute;
  background    : $primary;
  color         : white;
  font-size     : 15px;
  font-weight   : bold;
  left          : -16px;
  top           : -55px;
  height        : 32px;
  width         : 32px;
  text-align    : center;
  padding-top   : 7px;
  border-radius : 50%;
  z-index       : 9;
}

.pin-fill {
  position      : absolute;
  background    : $primary;
  color         : white;
  font-size     : 15px;
  font-weight   : bold;
  left          : -16px;
  top           : -55px;
  height        : 32px;
  width         : 32px;
  text-align    : center;
  padding-top   : 7px;
  border-radius : 50%;
  z-index       : 0;
}

.map-pin {
  $map-pin-size : 64;

  position      : absolute;
  color         : white;
  text-shadow   : 0px 0px 5px $primary;

  font-size     : ($map-pin-size * 1.109375)+px;
  left          : ($map-pin-size*1.109375 / 2 * -1)+px;
  top           : ($map-pin-size * -1)+px;
  height        : ($map-pin-size)+px;
  width         : ($map-pin-size * 1.109375)+px;
}

.closeMapPin {
  position  : absolute;
  top       : 3px;
  right     : 3px;
  font-size : 2.25rem;
  cursor    : pointer;
  color     : black;
  z-index   : 2;
}

.addresses-item {
  img {
	max-width : 100px;
  }
}

.modal-backdrop {
  background : rgba(0, 0, 0, 0.75);
}

.fade.in {
  opacity : 1 !important;
}


.product-card-img {
  object-fit      : cover;
  height          : 300px;
  width           : 100%;
  object-position : center;
  /*width: 100%;
  object-position: top center;
  object-fit: cover;*/
}

.inlineLocationField {
  background    : #FFF;
  padding       : 11px 16px;
  font-size     : 13px;
  border-radius : 2px;
  min-height    : 38px;
}

.owl-carousel-category {
  .owl-stage {
	display         : flex;
	justify-content : stretch;

	.item {
	  height : 100%;

	  .category-item {
		display         : flex;
		justify-content : center;
		flex-direction  : column;
		height          : calc(100% - 5px);
	  }
	}
  }
}

.select2-smaller {
  .select2 {
	max-width   : 80px !important;
	margin-left : 15px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
	left : 90%;
  }
}

.select2-container--open {
  z-index : 999999;
}

.card-overlay {
  background : #FFF;
  height     : 100%;

  .close-card {
	text-align : left;

	i {
	  font-size : 1.5rem;
	  cursor    : pointer;
	}
  }

  .basket-content {
	height   : calc(100% - 200px);
	overflow : auto;
  }
}

.checkout-product-img {
  max-height : 50px;
  object-fit : contain;
}

.border-width-2 {
  border-width : 2px !important;
}

.address-select {
  z-index : 3;
}

.edit-button {
  background    : #EEE;
  border-radius : 2px;
  padding       : 7px 10px;
  display       : inline-block;
  font-size     : 1rem;
  cursor        : pointer;
}

.bigger-badge {
  font-size : 1rem;
  padding   : 10px;
  display   : inline-block;
}

.headerForm {
  min-width  : 500px;
  max-Width  : 100%;
  min-height : 74px;
}

.search-close {
  font-size : 1.2rem;
}

.category-list {
  list-style   : none;
  padding-left : 20px;

  li:before {
	content      : '\00BB';
	margin-left  : -20px;
	margin-right : 10px;
  }
}

.columned-list {
  columns         : 2;
  -webkit-columns : 2;
  -moz-columns    : 2;
  column-rule     : 4px double #CCC;
  column-span     : 4;
}

.custom-location-field {
  .locate-me {
	margin-top   : -4px;
	margin-right : -4px;
  }
}

.text-shadow {
  text-shadow : rgba(49, 49, 49, 0.2) 0 2px 2px;
}

.rs-checkbox-wrapper::before {
  border : 1px solid #97BF04;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color     : #97BF04;
  background-color : #97BF04;
}

.rs-check-tree {
  max-height : 100%;
  overflow   : hidden;
}

.rs-slider-progress-bar {
  position         : absolute;
  height           : 6px;
  border-radius    : 3px 0 0 3px;
  background-color : #97BF04;
}

.rs-slider-handle::before {
  border : 2px solid #97BF04;
}

.custom-label {
  padding-top    : calc(var(--input-padding-y) / 3);
  padding-bottom : calc(var(--input-padding-y) / 3);
  font-size      : 12px;
  color          : #777;
  margin-top     : 10px;
}

.abo-info {
  border-top  : 1px solid #7A7E8A;
  padding-top : 5px;
}

.smaller-map {
  max-height : 600px;
}

.form-check-paid {
  background : transparentize($primaryNeon, .7);
  padding    : 1rem 0.75rem;

  .form-check-input {
	position : relative;
	margin   : 0 0.5rem 0 0;
  }
}

.category-tag {
  font-size : 0.75rem;
}

.inlineTitleEditField {
  font-size : 1rem;
}

.add-collection {
  background    : $primary;
  border-radius : 4px;
  color         : $white;
  padding       : 10px;
  margin-left   : 5px;
  font-size     : 1rem;
  align-items   : center;
  display       : flex;
  cursor        : pointer;
}

.text-primaryNeon-green {
  color : $primaryNeon;
}

.text-primaryNeon-green-50 {
  color : transparentize($primaryNeon, 0.5);
}

.text-italic {
  font-style : italic;
}

.homepage-search-title {
  background : rgba(0, 0, 0, 0.2);
  padding    : 10px;
}

.header-bg-green {
  display    : inline-block;
  padding    : 10px 20px;
  background : rgba(255, 255, 255, 0.8);
}

.header-bg {
  display    : inline-block;
  padding    : 20px 40px;
  background : rgba(0, 0, 0, 0.3);
}

.flex-owl {
  .owl-stage {
	display : flex !important;
  }
}


.osahan-nav .nav-link.icon-only {
  margin-left : 1rem !important;
  font-size   : 1.25rem;
  padding     : 20px 0 !important;

  i {
	margin-top : 5px;
  }

  .badge {
	font-size     : 10px;
	width         : 18px;
	height        : 18px;
	border-radius : 50%;
	padding-top   : 4px;
	text-align    : center;
	margin-top    : -10px;
  }
}

.navbar-light .navbar-toggler {
  color        : rgba(0, 0, 0, 0.5);
  border-color : transparent;
}

.mobile-nav {
  position           : absolute;
  top                : 100px;
  left               : 0;
  z-index            : 99999;
  width              : 100%;
  background         : #FFF;
  max-height         : calc(100vh - 300px);
  overflow-y         : auto;
  overflow-x         : hidden;
  -webkit-box-shadow : 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow    : 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow         : 0px 5px 5px 0px rgba(0, 0, 0, 0.1);

  .nav-link {
	text-align    : left;
	font-size     : 1rem;
	padding-left  : 20px !important;
	padding-right : 20px !important;
	border-bottom : 1px solid #E1E2DE;
  }

  .mobile-header-search {
	border-top     : 1px solid #EEE;
	padding-left   : 20px !important;
	padding-right  : 20px !important;
	margin-top     : 10px;
	padding-bottom : 20px;
	border-bottom  : 1px solid #E1E2DE;
  }

  .dropdown-menu {
	border-radius : 0;
	border-left   : none;
	border-right  : none;

	.dropdown-item {
	  text-align    : left;
	  padding-left  : 20px !important;
	  padding-right : 20px !important;
	  font-size     : 01rem;
	}
  }

  .icon-only-mobile {
	color     : rgba(0, 0, 0, 0.5);
	font-size : 1.5rem;

	i {
	  margin-top : 10px;
	  font-size  : 2rem;
	}
  }

  .navbar-nav {
	flex-direction : row;
	flex-wrap      : wrap;
  }
}

.osahan-nav .mobile-nav .nav-link {
  width : 100%;

  &.icon-only {
	width       : 50%;
	text-align  : center;
	font-size   : 1.5rem;
	margin-left : 0 !important;
  }

  &.login-nav-item {
	padding-top : 25px !important;
	width       : 50%;
  }

  &.searchIcon {
	display : none;
  }
}

.osahan-nav .mobile-nav .nav-item {
  &.login-nav-item {
	padding-top : 15px;
	width       : 50%;

	.nav-link {
	  border-bottom : none;
	}
  }
}

/*Responsive*/
@media (max-width : 991px) {
  #big .item img {
	background : #FFF;
	height     : 300px;
	width      : 100%;
	object-fit : contain;
  }
  .osahan-account-page-left .nav-link {
	padding : 5px 10px;
  }
  .rs-steps-vertical {
	display        : flex;
	flex-wrap      : wrap;
	flex-direction : row;
  }
  .rs-steps-item-tail {
	display : none;
  }
  .rs-steps-item {
	width      : 50%;
	margin-top : 0 !important;
  }
  .rs-steps-item-content {
	width : calc(50% - 20px);
  }
  .media {
	display : block;

	.media-body {
	  margin-top : 20px;
	}
  }
}

@media (max-width : 576px) {
  #big .item img {
	background : #FFF;
	height     : 250px;
	width      : 100%;
	object-fit : contain;
  }
  .osahan-nav .mobile-nav .nav-link {
	width : 100%;

	&.icon-only {
	  width : 100%;
	}

	&.login-nav-item {
	  padding-top : 15px !important;
	  width       : 100%;
	}
  }
  .osahan-nav .mobile-nav .nav-item {
	&.login-nav-item {
	  padding-top   : 5px;
	  width         : 100%;
	  border-bottom : 1px solid #E1E2DE;
	}
  }

}

.dsgvo-widget__popup-bg {
  z-index    : 10000;
  position   : fixed;
  top        : 0;
  right      : 0;
  bottom     : 0;
  left       : 0;
  background : #0D0A0A;
  opacity    : 0.9;
}

.dsgvo-widget__popup {
  position      : fixed;
  top           : 50%;
  left          : 50%;
  transform     : translate(-50%, -50%);
  padding       : 35px;
  font-size     : 13px;
  width         : 90%;
  max-width     : 900px;
  z-index       : 10001;
  color         : #7A7A7A;
  background    : #FFF;
  box-sizing    : border-box;
  border-radius : 3px;
  box-shadow    : 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);

  .dsgvo-widget__popup-welcome {
	width       : 100%;
	font-weight : 700;
	font-size   : 22px;
  }

  .dsgvo-widget__popup-main {
	display         : flex;
	justify-content : space-between;
  }

  .dsgvo-widget__popup-main > div {
	width : 48%;
  }

  .dsgvo-widget__popup-categories-item {
	display         : -ms-flexbox;
	display         : flex;
	-ms-flex-pack   : justify;
	justify-content : space-between;
	margin-bottom   : 14px;
  }

  .dsgvo-widget__popup-categories-item-name {
	display            : -ms-flexbox;
	display            : flex;
	-ms-flex-pack      : center;
	justify-content    : center;
	-ms-flex-direction : column;
	flex-direction     : column;
	max-width          : calc(100% - 72px);
  }

  .dsgvo-widget__popup-categories-item-count {
	color     : #989898;
	font-size : 11px;
  }
}

.rs-btn-toggle-checked {
  background-color : $primary;
}

.rs-btn-toggle-checked:hover {
  background-color : transparentize($primary, 0.2);
}

.rs-btn-toggle-disabled.rs-btn-toggle-checked, .rs-btn-toggle-disabled.rs-btn-toggle-checked:hover {
  background-color : transparentize($primary, 0.8);
  //background: #747474;
}

.reg-header {
  font-size : 3rem;
}

.reg-desc {
  white-space   : pre-wrap;
  margin-top    : 1.25rem;
  margin-bottom : 1.25rem;
  font-size     : 1rem;
}

.closeMenu {
  position   : fixed;
  top        : 110px;
  left       : 0;
  height     : 100%;
  width      : 100%;
  background : rgba(0, 0, 0, 0.5);
}

.product-grid-btn {
  color     : $primary;
  font-size : 1.25rem;
  cursor    : pointer;
}

.search-address-field {
  max-width     : 100%;
  margin-bottom : 5px;

  .address-title {
	padding       : 7px 10px;
	font-size     : 12px;
	border-radius : 2px;
	max-width     : 90%;
	background    : #FFF;
  }

  .editAddress {
	background : #FFF;
	cursor     : pointer;
	font-size  : 12px;
	padding    : 7px 16px;
  }
}

.home-search-holder {
  padding-top    : 18px;
  padding-bottom : 18px;
}

@media (max-width : 767px) {
  .sliding-panel-container {
	height : 100%;

	.panel-container-right-enter-done {
	  height : 100%;
	}

	.glass, .panel {
	  height : 100% !important;
	}
  }
  .reg-header {
	font-size : 2rem;
  }
}

.bulk-box {
  border : 1px solid rgba(0, 0, 0, 0.125);
}


.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
  font-family : IcoFont !important;
  color       : $primary;
  font-size   : 20px;
  margin-top  : 9px;
  padding     : 6px 0;
  line-height : 20px;
}

.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon::before {
  content : '\ea86';
}

.rs-check-tree-open > .rs-check-tree-node .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
  margin-left : -8px;
  margin-top  : 2px;
}

.product-address {
  .inlineLocationField {
	padding    : 9px 15px;
	min-height : auto;
  }

  .icofont-ui-edit {
	padding-top : 2px;
  }

  .location-field {
	input {
	  padding    : 5px 10px;
	  min-height : auto;
	}

	.autocomplete-dropdown-container {
	  border : 1px solid #EEE;
	}

	.suggestion-item {
	  color : #000;
	}

	.locate-me {
	  span {
		display : none;
	  }
	}
  }
}

.custom-tooltip {
  .rs-tooltip-inner {
	border           : 2px solid $primary;
	padding          : 10px 15px;
	background-color : #FFF;
	max-width        : 100%;
	width            : auto;
	max-height       : 250px;
	overflow-x       : auto;
  }
}

.custom-tooltip[class*='placement-top'] .rs-tooltip-arrow {
  border-top-color : $primary;
}

.custom-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
  border-bottom-color : $primary;
}

.inline-location-container-info {
  width     : 500px;
  max-width : 100%;
}

.product-address {
  .info-tooltip {
	i {
	  font-size : 25px !important;
	}
  }
}

.text-location-small {
  font-size : 12px;
}

.product-list-item {
  .product-list-bottom {
	padding-bottom : 5px;
	margin-bottom  : 5px;
	border-bottom  : 1px solid #EEE;
  }

  .product-list-img {
	max-height : 75px;
	object-fit : contain;
  }

  button, a {
	font-size : 12px;
  }
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border  : 1px solid $primary;
  padding : 4px;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color            : #FFF;
  border-color     : $primary;
  background-color : $primary;
}

.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled, .rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover, .rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover:active {
  background-color : $primary;
}

.rs-calendar-table-cell-in-range::before {
  background-color : rgba(97, 140, 3, 0.2);
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color : $primary;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color : $primary;
}

.rs-picker-toolbar-right-btn-ok {
  background-color : $primary;
}

.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
  background-color : $primaryDark;
}

.product-card-bottom-link {
  padding          : 10px;
  margin-left      : -16px;
  margin-right     : -16px;
  background-color : $primaryNew;
  color            : #FFF !important;
  transition       : 0.3s;

  &:hover {
	background : $primary;
  }
}

.header-banner {
  padding    : 15px;
  text-align : center;
  color      : #FFF;
  background : $primaryNew;
  font-size  : 18px;
}

.start-page-link {
  color       : #FFF !important;
  padding     : 10px 30px;
  min-width   : 30%;
  text-align  : center;
  background  : $primaryNew;
  font-size   : 20px;
  font-weight : 600;
  transition  : 0.3s;

  &:hover {
	background : $primary;
	color      : #FFF !important;
  }
}

.react-multi-carousel-list {
  padding-bottom : 50px;
}

.home-carousel-item {
  padding : 10px;
  height  : 100%;
}

.carousel-button-group {
  position   : absolute;
  bottom     : 0;
  width      : 100%;
  text-align : center;

  .home-carousel-button {
	padding   : 10px;
	display   : inline-block;
	cursor    : pointer;
	color     : $primary;
	font-size : 16px;

	i {
	  font-size : 26px;
	}
  }

  .divider {
	border-right : 2px solid $primary;
	height       : 25px;
	width        : 1px;
  }
}

.home-teaser-section {
  h5 {
	color       : $primaryNew;
	font-weight : 300;
  }
}

.home-teaser-item {
  img {
	max-width  : 100px;
	object-fit : contain;
  }

  .home-teaser-content {
	padding       : 15px 0;
	padding-right : 15px;
	font-size     : 16px;

	.home-teaser-title {
	  display       : block;
	  white-space   : pre-line;
	  color         : $primaryNew;
	  font-weight   : 800;
	  margin-bottom : 5px;
	}

	p {
	  font-size   : 14px;
	  white-space : pre-line;
	}
  }
}

@media (max-width : 767px) {
  .homepage-search-block {
	padding-top         : 5rem;
	padding-bottom      : 5rem;
	background-image    : url("../assets/img/header_startseite_mobile.jpg") !important;
	background-color    : #B1B1B1;
	background-size     : contain;
	background-position : center;
	background-repeat   : no-repeat;

	.header-bg {
	  background : none;
	  padding    : 20px;

	  .text-primaryNew {
		color : #FFF !important;
	  }
	}
  }
  .home-teaser-item {
	text-align : center;
  }
}

.fade {
  pointer-events : all !important;
}

.vendor-new-img{
  height: 100px;
  object-fit: contain;
}

.dsgvo-widget__popup-bg {
  opacity: 0.6!important;
}

.dsgvo-widget__popup {
  max-height: 75vh!important;
}

.card-icon-overlap {
  i.icofont-info-circle {
   color: #333;
    margin-right: 5px;
  }

  .form-check-label {
    display: inline!important;
  }
}