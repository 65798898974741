@import "../../theme/colors.scss";

.location-field {
  position : relative;

  .locate-me {
	background    : #ECECEC none repeat scroll 0 0;
	color         : #666;
	font-size     : 12px;
	padding       : 7px 10px;
	border-radius : 3px;
	position      : absolute;
	right         : 14px;
	top           : 9px;
	border        : none;

	&:hover {
	  background : #666 none repeat scroll 0 0;
	  color      : $white;
	}
  }

  .location-search-input,
  .location-search-input:focus,
  .location-search-input:active {
	box-shadow : 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
	border     : none;
	padding    : 16px;
	outline    : none;
  }

  .autocomplete-dropdown-container {
	border   : none;
	position : absolute;
	z-index  : 9000;
	width    : calc(100% - 10px);
	//box-shadow : 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  }

  .suggestion-item {
	padding          : 8px;
	text-align       : left;
	background-color : #FFF;
	cursor           : pointer;

	&.active {
	  background-color : $lightGreenBackground !important;
	}
  }
}
