// @flow
// variables.scss
$white                : #F2F2F2;
$lightBackground      : #F3F7F8;
$text                 : #313131;
$textLight            : #7A7E8A;
$primary              : #618C03;
$primaryLight         : #A5BF6B;
$primaryNeon          : #97BF04;
$primaryDark          : #365902;
$red                  : #FF3008;
$danger               : #F32129;
$primaryLight2        : #D2EA9C;
$primaryNew           : #97BF04;

$lightGreenBackground : mix($primary, $white, 5);

// the :export directive is the magic sauce for webpack
//noinspection ALL
:export {
  white           : $white;
  lightBackground : $lightBackground;
  text            : $text;
  primary         : $primary;
  primaryLight    : $primaryLight;
  primaryNeon     : $primaryNeon;
  primaryDark     : $primaryDark;
}
